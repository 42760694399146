<template>
	<div class="a-w-100 page ">
		<div class="a-flex-rsbc a-ptb-15">
			<span class="a-fs-16 a-fw-b">普查管理</span>
			<le-export-btn :pageParam="pageParam"></le-export-btn>
		</div>

		<el-card class="el-main">

			<le-search-form @reset="handlerRest" @search="handlerSearch">
				<le-input-district-choose label="请选择省市" :province.sync="pageParam.params.provinceCode"
					:city.sync="pageParam.params.cityCode"
					:area.sync="pageParam.params.areaCode"></le-input-district-choose>
				<le-input label="地址" v-model="pageParam.params.address" />
				<le-input label="供管所" v-model="pageParam.params.managerName" />
				<le-input label="分站点" v-model="pageParam.params.stationName" />
				<le-date-range label="勘探时间" :minDate.sync="pageParam.params.startTime"
					:maxDate.sync="pageParam.params.endTime" />
				<le-select-remote-search label="用户" v-model="pageParam.params.userId" :options="optionsUser"
					placeholder="请选择（可搜索）" />
			</le-search-form>

			<le-pagview @setData="setTableData" :pageParam="pageParam" id="table_box">
				<el-table :data="tableData" :highlight-current-row="true" style="width: 100%"
					v-sticky="{ parent: '.el-card__body' }">
					<el-table-column prop="mainInfo.id" label="序号" width="50" >
					</el-table-column>
					<el-table-column prop="mainInfo.area" label="区域" width="150" align="center">
					</el-table-column>
					<el-table-column prop="mainInfo.address" label="地址" width="250" align="center">
					</el-table-column>
					<el-table-column prop="mainInfo.managerName" label="供管所" width="150" align="center">
					</el-table-column>
					<el-table-column prop="mainInfo.stationName" label="分站点" width="150" align="center">
					</el-table-column>
					<el-table-column prop="mainInfo.caliber" label="口径" width="70" align="center">
					</el-table-column>
					<el-table-column prop="mainInfo.dep" label="埋深" width="70" align="center">
					</el-table-column>
					<el-table-column prop="mainInfo.type" label="漏水性质" width="150" align="center">
					</el-table-column>
					<el-table-column prop="mainInfo.pipe" label="管材" width="80" align="center">
					</el-table-column>
					<el-table-column prop="user.nickName" label="普查人员" width="100" align="center">
					</el-table-column>
					<el-table-column prop="mainInfo.createTime" label="普查时间" width="200" align="center">
					</el-table-column>
					<el-table-column label="照片" align="center">
						<template slot-scope="{ row }">
							<div class="a-flex-rfsc a-w-100">
								<div v-for="(item,index) in row.pics" :key="item" class="a-plr-10">
									<el-image :src="item" style="width: 55px;height:55px;" fit="cover" :preview-src-list="row.pics"></el-image>
								</div>
							</div>
						</template>
					</el-table-column>

				</el-table>
			</le-pagview>
		</el-card>
	</div>
</template>

<script>

export default {

	data() {
		return {
			optionsUser: {
				url: this.$Config.apiUrl.getUserListByNickName,
				method: "post",
				params: {},
				labelKey: "nickName",
				valueKey: "id",
				searchKey: "nickName",
			},
			pageParam: {
				url: this.$Config.apiUrl.getList,
				method: "post",
				params: {
					provinceCode: "310000",
					cityCode: "310100",
					areaCode: "",
					address: "",
					managerName: "",
					stationName: "",
					startTime: "",
					endTime:"",
					userId:"",

				},
				freshCtrl: 1,
			},
			tableData: [],
		};
	},

	mounted() { },
	methods: {

		setTableData(data) {
			this.tableData = data;

		},

		handlerRest() {
			this.pageParam.params = {
				provinceCode: "",
				cityCode: "",
				areaCode: "",
				address: "",
				managerName: "",
				stationName: "",
				startTime: "",
				endTime:"",
				userId:"",
			};
			this.pageParam.freshCtrl++
		},

		handlerSearch() {
			this.pageParam.freshCtrl++;
		},
	}
};
</script>

<style lang="scss" scoped></style>
